/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react' 
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import { TeamListing } from '../../DAL/Team/Team';
import { fileUrl } from '../../config/config';


const Team = () => {
    const [data, setData] = useState([]);
    console.log(data,"TeamListing")
    const [loading, setLoading] = useState();
   const TeamMembers = async () =>{
        setLoading(true);
        const result = await TeamListing();
       
        if (result.status === true) {
          setData(result?.data);
          setLoading();
      };
   }
   useEffect(() => {
    TeamMembers();
  }, []);
  return (
    <>
       <div className="container mt-5 mb-5">
        <div className="row">
        {data.map((val)=>{
            return(
                <div className="col-lg-4  mb-5" >
            <Card sx={{ maxWidth: 345, height: 300 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={`${fileUrl}${val.image}`}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {val.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
  {val.description.length > 90 
    ? `${val.description.substring(0, 90)}...` 
    : val.description}
</Typography>

        </CardContent>
      </CardActionArea>
        <div style={{display:"flex",justifyContent:"end"}}>
      <CardActions>
        <Button size="small" color="primary" href={val.links.startsWith('http') ? val.links : `http://${val.links}`} target="_blank" rel="noopener noreferrer">
          Visit
        </Button>
      </CardActions>
        </div>
    </Card>
            </div>
            )
        })}
            
        </div>
       </div>
    </>
  )
}

export default Team
