import { invokeApi } from "../../utils";

export const TeamListing = async (data) => {
  const requestObj = {
    path: `api/join/list`,
    method: "GET",
    headers: {

     },
    postData:data
  };

  return invokeApi(requestObj);
};
