import React, { useState } from 'react';
import { TextField, Box, Input } from '@mui/material';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import video from '../../assets/video/video.mp4'

const Form = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [linkError, setLinkError] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    image: null,
    link: '',
    description: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    const isValidLink = /^(https?:\/\/)?(www\.)?[\w-]+\.(com|net|org|edu|gov|co)(\.[a-z]{2})?/.test(value);
    setLinkError(!isValidLink);
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formDataObj = new FormData();
    formDataObj.append('name', formData.name);
    formDataObj.append('image', formData.image);
    formDataObj.append('link', formData.link);
    formDataObj.append('description', formData.description);
   
    try {
      const response = await axios.post('https://paklivestream.com/backend/api/join/add', formDataObj, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setFormData({
        name: '',
        image: null,
        link: '',
        description: '',
      });
      setIsLoading(false);
      console.log('Form submitted successfully:', response.data);
    } catch (error) {
      console.error('Error submitting the form:', error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <video src={video} autoPlay muted loop className='video' ></video>
    <div>
      <div className='form-page'  style={{display:"flex",flexDirection:"column",alignItems:"center",height:"90vh"}}>
    <Box
      component="form"
      onSubmit={handleSubmit}
      
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent:"center",
        background: "rgba(0, 0, 0, 0.5)",
        gap: 2,
        maxWidth: 700,
        width:"100%",
        margin: 'auto',
        padding: 2,
        border: '1px solid lightgray',
        borderRadius: 2,
        "& label.Mui-focused": {
            color: "white",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },
          },
          "& input::placeholder": {
            color: "white",
            opacity: 1,
          },
          "& input": {
            color: "white",
          },
          "& label": {
            color: "white", // Default label color
          },
          "& input, & textarea": {
            color: "white",
          },
          "& textarea::placeholder": {
            color: "white", // Placeholder color for the description textarea
            opacity: 1,
          },
      }}
    >
      <TextField
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        required
        fullWidth
      />

      <Input
        type="file"
        name="image"
        onChange={handleImageChange}
        required
      />

      <TextField
        label="Linkdin Link"
        name="link"
        value={formData.link}
        onChange={handleInputChange}
        required
        fullWidth
        error={linkError}
        helperText={linkError ? "Please enter a valid link (e.g., www.example.com)" : ""}
      />

      <TextField
        label="Description"
        name="description"
        value={formData.description}
        onChange={handleInputChange}
        multiline
        placeholder='Must contain exactly 90 characters.'
        rows={4}
        required
        fullWidth
        inputProps={{ maxLength: 90 }}
      />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        className="Add-button text-white"
        style={{marginTop:"10px"}}
        variant="contained"
        loading={isLoading}
        disabled={linkError || !formData.link}
      >
        {isLoading === true ? "Submit.." : "Submit"}
      </LoadingButton>
    </Box>
    </div>
    </div>
    </>
  );
};

export default Form;
