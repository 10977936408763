import React from "react";

const Contact = () => {
  return (
    <>
    <div className="imgcontact">
    <div className="text-center pt-5">
    <p >Have any queries?</p>
        <h1>We’re here to help.​</h1>
        <hr />
    </div>
    <div className="animationLeft" style={{marginTop:"170px",marginLeft:"100px"}}>
         <h2>Contact Us</h2>
         <hr style={{width:"20%"}} /> <br />
         <a href="tel:+84384816374" className="text-white h5">+84 38 481 6374</a>
          <br /> <br />
          <a href="mailto:info@paklivestream.com" className="h5 text-white">info@paklivestream.com</a> <br /> <br />
         
          
         </div>
    </div>
    </>
  );
};

export default Contact;
