import React from 'react'
import logo from '../../assets/Images/paklogo.png'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <>
    <div style={{width:"95%",margin:"auto"}}>
      <div className="row">
        <div className="col-lg-6 d-flex justify-content-start">
        <Link to={"/"}><img src={logo} alt="" className='App-logo '/></Link>
        </div>
        <div className="col-lg-6 ">
            <ul className='d-flex justify-content-end gap-5 mt-4'>
                <Link to={"/contact"} className='list'><li>Contact Us</li></Link>
                <Link to={"/team"} className='list'><li>Team Members</li></Link>
                <Link to={"/form"} className='list'><li className='btn'>Join Us</li></Link>
            </ul>
        </div>
      </div>
    </div>
    </>
  )
}

export default Navbar
