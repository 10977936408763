import React from 'react'
import { Link } from "react-router-dom";


const Home = () => {
  return (
    <>
    <div className='image'>
      <div className="container">
        <h1>Welcome to Pakistan's first Live-Streaming Union.</h1>
      </div>
      </div>
      <div className="container-1">
        <h2>We are proud to be the first and only live-streaming union in Pakistan, specifically formed to advocate for the rights and well-being of employees working for international live-streaming organizations operating within the country. As pioneers in this effort, we are dedicated to ensuring that these employees receive fair treatment, just compensation, and a supportive work environment, regardless of their role or level of experience.</h2>
        <h1>Our Mission</h1>
        <p>Our mission is rooted in the protection and promotion of workers' rights, particularly those who have experienced unfair treatment, exploitation, or harassment from their employers. We are committed to addressing issues such as inappropriate behavior, lack of transparency, unjust wage practices, and other forms of misconduct that may arise in an industry that is rapidly expanding but often lacks adequate regulation.</p>
        <p>In addition to addressing individual grievances, we strive to foster a culture of accountability and responsibility among employers operating in Pakistan. By establishing clear standards and advocating for the enforcement of both national and international labor laws, we aim to create a fairer and more equitable working environment for all employees.</p>
        <p>This union is not just about providing support during times of hardship but also about empowering workers to stand up for their rights and ensure they are treated with the dignity and respect they deserve. We believe that by uniting and amplifying our collective voice, we can bring about meaningful changes in the live-streaming industry and create a sustainable, transparent, and fair work culture for all employees operating within this sector.</p>
        <p>We welcome all employees who feel marginalized, mistreated, or disrespected by their employers to join us in this cause. Together, we can shape a future where workers are valued, heard, and protected.</p>
        <Link to={"/form"}><button >Join Us</button></Link>
      </div>
    </>
  )
}

export default Home
