
import './App.css';
import 'animate.css';
import Home from './Pages/Home/Home';
import Navbar from './Pages/Navbar/Navbar';
import Contact from './Pages/Contact/Contact';
import Form from './Pages/Form/Form';
import { Route, Routes } from "react-router-dom";
import Team from './Pages/About/Team';

function App() {
  return (
    <>
    <Navbar/>
    <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/contact' element={<Contact/>}/>
    <Route path='/form' element={<Form/>}/>
    <Route path='/team' element={<Team/>}/>
    </Routes>
    </>
  );
}

export default App;
